import { faTrash, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Checkbox, Table } from 'antd';
import { filter, find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getCachedAllUsers,
  getCachedCurrentUser,
  getCachedUserInfo,
} from '../helpers/storeHelper';
import { User } from '../../@types/User';
import { userFullName } from '../../utils/helpers';
import AllUsersSelector from '../shared/AllUsersSelector';
import { AccountPermissionParams } from '../../api/accountPermissions';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface AccountPermissionsFormTableRecord {
  key: string;
  name: string;
  updateAction: (event: any) => any;
  deleteAction: (event: any) => any;
  isSubscribedToNotifications: boolean;
  editMode: boolean;
  canDelete: boolean;
  canUpdate: boolean;
}

const columnDefs = [
  {
    key: 'deleteAction',
    render: (value: any, record: AccountPermissionsFormTableRecord) => (
      <>
        {record.canDelete && (
          <Button onClick={record.deleteAction} type="link">
            <FontAwesomeIcon icon={faTrash} color="red" />
          </Button>
        )}
      </>
    ),
  },
  {
    dataIndex: 'name',
    key: 'name',
  },
  {
    dataIndex: 'subscribeToNotifications',
    key: 'subscribe_to_notifications',
    title: () => <FontAwesomeIcon icon={faMailBulk} color="Green" />,
    render: (value: any, record: AccountPermissionsFormTableRecord) => (
      <>
        {record.canUpdate && (
          <Checkbox
            checked={record.isSubscribedToNotifications}
            onChange={(event: CheckboxChangeEvent) =>
              record.updateAction({
                subscribe_to_notifications: event.target.checked,
              })
            }
          />
        )}
      </>
    ),
  },
];

interface AccountPermissionsFormProps {
  accountPermissionParamsList: AccountPermissionParams[];
  editMode?: boolean;
  deleteAction?: (accountPermissionParams: AccountPermissionParams) => void;
  updateAction?: (accountPermissionParams: AccountPermissionParams) => void;
  createAction?: (accountPermissionParams: AccountPermissionParams) => void;
}

interface AccountPermissionsFormState {
  columnDefs: any[];
  dataSource: AccountPermissionsFormTableRecord[];
  possibleUsers: User[];
}

const AccountPermissionsForm = ({
  accountPermissionParamsList = [],
  deleteAction,
  updateAction,
  createAction,
  editMode = false,
}: AccountPermissionsFormProps) => {
  const { t } = useTranslation();
  const allUsers = getCachedAllUsers();
  const currentUser = getCachedCurrentUser();

  const [state, setState] = useState<AccountPermissionsFormState>({
    columnDefs: columnDefs,
    dataSource: [],
    possibleUsers: [],
  });

  useEffect(() => {
    let adjustedColumnDefs: any = columnDefs;
    if (editMode) {
      adjustedColumnDefs = filter(
        adjustedColumnDefs,
        (columnDef) => columnDef.key !== 'subscribe_to_notifications',
      );
    }
    // use translated names as we cannot use them inside the columns defs
    for (let i = 0; i < adjustedColumnDefs.length; i++) {
      if (adjustedColumnDefs[i].key === 'name') {
        adjustedColumnDefs[i]['title'] = t(
          'accountPermission.form.userNameColumn',
        );
      }
    }

    setState({
      columnDefs: adjustedColumnDefs,
      dataSource: accountPermissionParamsList.map(
        (accountPermissionParams) => ({
          key: `${accountPermissionParams.user_id}`,
          name: nameForUser(accountPermissionParams.user_id),
          isSubscribedToNotifications:
            !!accountPermissionParams.subscribe_to_notifications,
          deleteAction: (event: any) =>
            deleteAction && deleteAction!(accountPermissionParams),
          updateAction: (
            updatedAccountPermissionsParam: Partial<AccountPermissionParams>,
          ) =>
            updateAction &&
            updateAction!({
              ...accountPermissionParams,
              ...updatedAccountPermissionsParam,
            }),
          editMode,
          canDelete:
            !!deleteAction &&
            (editMode || accountPermissionParams.user_id === currentUser.id),
          canUpdate:
            !!updateAction &&
            !editMode &&
            accountPermissionParams.user_id === currentUser.id,
        }),
      ),
      possibleUsers:
        filter(
          allUsers,
          (user) =>
            user.id !== currentUser.id &&
            !find(accountPermissionParamsList, { user_id: user.id }),
        ) || [],
    });
  }, [JSON.stringify(accountPermissionParamsList)]);

  /*
   * Helpers
   */
  const nameForUser = (userId: number) => {
    if (userId === currentUser.id)
      return `${userFullName(currentUser)} (${t('user.you')}) - ${currentUser.email}`;

    const user = getCachedUserInfo(userId)!;
    return `${userFullName(user)} - ${user.email}`;
  };

  const onPossibleUserSelected = (user: User) => {
    createAction!({ user_id: user.id });
  };

  /*
   * Ui parts
   */

  return (
    <>
      <Alert
        message={t('accountPermission.form.explanation.title')}
        description={t('accountPermission.form.explanation.body')}
        type="info"
        showIcon
        style={{ marginBottom: '5px' }}
      />
      {editMode && (
        <AllUsersSelector
          possibleUsers={state.possibleUsers}
          onUserSelected={onPossibleUserSelected}
          placeholder={t('accountPermission.form.selectUser')}
          selectSize="middle"
        />
      )}

      <Table
        columns={state.columnDefs}
        dataSource={state.dataSource}
        pagination={false}
      />
    </>
  );
};

export default AccountPermissionsForm;
