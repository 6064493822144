import { API_SERVER } from '../utils/envVars';
import { deleteRequest, putRequest } from './apiHelper';
import { AccountTransaction } from '../@types/AccountTransaction';

export interface AccountPermissionParams {
  id?: number;
  subscribe_to_notifications?: boolean;
  user_id: number;
}

export const updateAccountPermissionApi = async (
  id: number,
  accountPermissionParams: AccountPermissionParams,
) => {
  const apiResponse = await putRequest<AccountTransaction>(
    `${API_SERVER}/account_permissions/${id}`,
    { account_permission: accountPermissionParams },
  );
  return apiResponse.data!;
};

export const deleteAccountPermissionApi = async (id: number) =>
  deleteRequest(`${API_SERVER}/account_permissions/${id}`);
