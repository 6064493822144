import { User } from '../@types/User';
import { Account } from '../@types/Account';
import { filter, find, flatten, pick } from 'lodash';
import { AccountParams } from '../api/account';
import { Currency } from '../@types/enums';
import { AccountPermission } from '../@types/AccountPermission';
import { format, parseISO } from 'date-fns';
import { message } from 'antd';
import { TFunction } from 'i18next';
import { AccountGroup } from '../@types/AccountGroup';
import { AccountGroupParams } from '../api/accountGroup';
import { getCachedAccountGroups } from '../components/helpers/storeHelper';
import { AccountPermissionParams } from '../api/accountPermissions';

export const isSharedWithMeGroup = (accountGroup: AccountGroup) =>
  accountGroup.name === 'shared_with_me';

export const userFullName = (user: User) =>
  `${user.first_name} ${user.last_name}`;

export const userInitials = (user: User) =>
  `${user.first_name[0]}${user.last_name[0]}`;

export const extractAllAccountsFromCachedAccountGroups = ({
  includeArchived = false,
  includeSharedWithMe = false,
}: {
  includeArchived?: boolean;
  includeSharedWithMe?: boolean;
} = {}) => {
  let accountGroups = getCachedAccountGroups()!;
  accountGroups =
    filter(accountGroups, (accountGroup) => !accountGroup.archived) || [];
  if (!includeSharedWithMe) {
    accountGroups = filter(
      accountGroups,
      (accountGroup) => !isSharedWithMeGroup(accountGroup),
    );
  }

  let accountsList = flatten(
    accountGroups.map((accountGroup) => accountGroup.accounts || []) || [],
  );

  if (!includeArchived) {
    accountsList = filter(accountsList, (account) => !account.archived);
  }

  if (!includeSharedWithMe) {
    accountsList = filter(accountsList, (account) => !account.archived);
  }

  return accountsList;
};

export const accountForId = (accountId: number) => {
  const accounts = extractAllAccountsFromCachedAccountGroups({
    includeArchived: true,
  });
  return find(accounts, (account) => account.id === accountId);
};

export const accountFullName = (account: Account) =>
  `${account.account_group.name} - ${account.name}`;

export const convertDateToApiDateString = (date: Date): string => {
  return format(date, 'yyyy-MM-dd');
};

export const convertApiDateStringToDate = (dateString: string): Date => {
  // date string coming from the rails api is by default in the 'yyyy-MM-dd' format
  return parseISO(dateString);
};

export const convertApiDateStringToDisplayString = (
  dateString: string,
): string => {
  // date string coming from the rails api is by default in the 'yyyy-MM-dd' format
  return dateString.replaceAll('-', '/');
};

export const showGenericOperationFailedMessage = (error: any, t: TFunction) => {
  message.error(t('generic.errors.operationFailed'));
};

export const initAccountPermissionParams = (
  accountPermission: AccountPermission,
): AccountPermissionParams => ({
  id: accountPermission.id,
  user_id: accountPermission.user_id,
  subscribe_to_notifications: accountPermission.subscribe_to_notifications,
});

export const initAccountPermissionParamsList = (
  accountPermissions: AccountPermission[],
): AccountPermissionParams[] =>
  accountPermissions.map((accountPermission) =>
    initAccountPermissionParams(accountPermission),
  ) || [];

export const initAccountParams = (account?: Account): AccountParams => {
  if (account) {
    return {
      ...pick(account, ['name', 'currency', 'archived']),
      permissions: initAccountPermissionParamsList(account.permissions),
    };
  }

  return { currency: Currency.USD, archived: false, permissions: [] } as any;
};

export const initAccountGroupParams = (
  accountGroup?: AccountGroup,
): AccountGroupParams => {
  if (accountGroup) {
    return {
      ...pick(accountGroup, ['name', 'archived']),
    };
  }

  return { archived: false } as any;
};
