import { Button, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles/accountForm.scss';
import AccountPermissionsForm from './AccountPermissionsForm';
import { AccountPermission } from '../../@types/AccountPermission';
import { initAccountPermissionParamsList } from '../../utils/helpers';
import { useMutation } from 'react-query';
import {
  AccountPermissionParams,
  deleteAccountPermissionApi,
  updateAccountPermissionApi,
} from '../../api/accountPermissions';
import {
  DEFAULT_ERROR_MESSAGE_DURATION,
  DEFAULT_SUCCESS_MESSAGE_DURATION,
} from '../../utils/appVars';
import { refreshAccountGroups } from '../helpers/storeHelper';

interface ViewAccountPermissionsModalProps {
  accountPermissions: AccountPermission[];
  onOk: () => any;
}

interface ViewAccountPermissionsModalState {
  accountPermissionParamsList: AccountPermissionParams[];
}

const ViewAccountPermissionsModal = ({
  accountPermissions = [],
  onOk,
}: ViewAccountPermissionsModalProps) => {
  const { t } = useTranslation();

  const [state, setState] = useState<ViewAccountPermissionsModalState>({
    accountPermissionParamsList: [],
  });

  const updateAccountPermissionMutation = useMutation(
    async (params: AccountPermissionParams) =>
      updateAccountPermissionApi(params.id!, params),
  );

  const deleteAccountPermissionMutation = useMutation(
    async (params: AccountPermissionParams) =>
      deleteAccountPermissionApi(params.id!),
  );

  const updateOrDeletePermission = async (
    accountPermissionParams: AccountPermissionParams,
    deleted?: boolean,
  ) => {
    const saveFn = deleted
      ? deleteAccountPermissionMutation
      : updateAccountPermissionMutation;

    try {
      await saveFn.mutateAsync(accountPermissionParams);

      message.success(
        t(`generic.messages.success`),
        DEFAULT_SUCCESS_MESSAGE_DURATION,
      );

      await refreshAccountGroups();
    } catch (error) {
      console.log('error', error);
      message.error(
        t(`generic.errors.operationFailed`),
        DEFAULT_ERROR_MESSAGE_DURATION,
      );
    }
  };

  useEffect(() => {
    setState({
      accountPermissionParamsList:
        initAccountPermissionParamsList(accountPermissions),
    });
  }, [accountPermissions]);

  const onOkClick = async (event: any) => {
    event.stopPropagation();
    onOk();
  };

  return (
    <Modal
      open
      title={t('accountPermission.list.title')}
      onCancel={onOkClick}
      footer={
        <Button key="ok" type="default" onClick={onOkClick}>
          {t('generic.actions.ok')}
        </Button>
      }
    >
      <div className="account-form__input">
        <AccountPermissionsForm
          accountPermissionParamsList={state.accountPermissionParamsList}
          updateAction={(accountPermissionParams: AccountPermissionParams) =>
            updateOrDeletePermission(accountPermissionParams, false)
          }
          deleteAction={(accountPermissionParams: AccountPermissionParams) =>
            updateOrDeletePermission(accountPermissionParams, true)
          }
        />
      </div>
    </Modal>
  );
};

export default ViewAccountPermissionsModal;
